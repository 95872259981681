@tailwind base;
@tailwind utilities;
@tailwind components;


@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@600&family=Poppins&display=swap');

body {
  background-color: #18181b;
}